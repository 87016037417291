

:root {
    --swiper-theme-color: #007aff;
  }
  .swiper-container {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    height: 100%;
    /* Fix of Webkit flickering */
    z-index: 1;
  }
  
  .swiper-container-vertical > .swiper-wrapper {
    flex-direction: column;
  }
  .swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
    padding: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .swiper-container-android .swiper-slide,
  .swiper-wrapper {
    transform: translate3d(0px, 0, 0);
  }
  .swiper-container-multirow > .swiper-wrapper {
    flex-wrap: wrap;
  }
  .swiper-container-multirow-column > .swiper-wrapper {
    flex-wrap: wrap;
    flex-direction: column;
  }
  .swiper-container-free-mode > .swiper-wrapper {
    transition-timing-function: ease-out;
    margin: 0 auto;
  }
  .swiper-slide {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
    transition-property: transform;
  }
  .swiper-slide-invisible-blank {
    visibility: hidden;
  }
  /* Auto Height */
  .swiper-container-autoheight,
  .swiper-container-autoheight .swiper-slide {
    height: auto;
  }
  .swiper-container-autoheight .swiper-wrapper {
    align-items: flex-start;
    transition-property: transform, height;
  }
  /* 3D Effects */
  .swiper-container-3d {
    perspective: 1200px;
  }
  .swiper-container-3d .swiper-wrapper,
  .swiper-container-3d .swiper-slide,
  .swiper-container-3d .swiper-slide-shadow-left,
  .swiper-container-3d .swiper-slide-shadow-right,
  .swiper-container-3d .swiper-slide-shadow-top,
  .swiper-container-3d .swiper-slide-shadow-bottom,
  .swiper-container-3d .swiper-cube-shadow {
    transform-style: preserve-3d;
  }
  .swiper-container-3d .swiper-slide-shadow-left,
  .swiper-container-3d .swiper-slide-shadow-right,
  .swiper-container-3d .swiper-slide-shadow-top,
  .swiper-container-3d .swiper-slide-shadow-bottom {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 10;
  }
  .swiper-container-3d .swiper-slide-shadow-left {
    background-image: linear-gradient(
      to left,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0)
    );
  }
  .swiper-container-3d .swiper-slide-shadow-right {
    background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0)
    );
  }
  .swiper-container-3d .swiper-slide-shadow-top {
    background-image: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0)
    );
  }
  .swiper-container-3d .swiper-slide-shadow-bottom {
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0)
    );
  }
  /* CSS Mode */
  .swiper-container-css-mode > .swiper-wrapper {
    overflow: auto;
    scrollbar-width: none;
    /* For Firefox */
    -ms-overflow-style: none;
    /* For Internet Explorer and Edge */
  }
  .swiper-container-css-mode > .swiper-wrapper::-webkit-scrollbar {
    display: none;
  }
  .swiper-container-css-mode > .swiper-wrapper > .swiper-slide {
    scroll-snap-align: start start;
  }
  .swiper-container-horizontal.swiper-container-css-mode > .swiper-wrapper {
    scroll-snap-type: x mandatory;
  }
  .swiper-container-vertical.swiper-container-css-mode > .swiper-wrapper {
    scroll-snap-type: y mandatory;
  }
  :root {
    --swiper-navigation-size: 20px;
    /*
      --swiper-navigation-color: var(--swiper-theme-color);
      */
  }
  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    top: 40%;
    width: calc(var(--swiper-navigation-size) / 44 * 27);
    height: var(--swiper-navigation-size);
    margin-top: calc(-1 * var(--swiper-navigation-size) / 2);
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--swiper-navigation-color, var(--swiper-theme-color));
    background-color: #ffffff;
    border-radius: 50%;
    color: #000000;
    padding: 24px;
  }
  .swiper-button-prev.swiper-button-disabled,
  .swiper-button-next.swiper-button-disabled {
    opacity: 0.35;
    cursor: auto;
    pointer-events: none;
  }
  .swiper-button-prev:after,
  .swiper-button-next:after {
    font-family: swiper-icons;
    font-size: var(--swiper-navigation-size);
    text-transform: none !important;
    letter-spacing: 0;
    text-transform: none;
    font-variant: initial;
  }
  .swiper-button-prev,
  .swiper-container-rtl .swiper-button-next {
    left: 10px;
    right: auto;
  }
  .swiper-button-prev:after,
  .swiper-container-rtl .swiper-button-next:after {
    content: "prev";
  }
  .swiper-button-next,
  .swiper-container-rtl .swiper-button-prev {
    right: 10px;
    left: auto;
  }
  .swiper-button-next:after,
  .swiper-container-rtl .swiper-button-prev:after {
    content: "next";
  }
  .swiper-button-prev.swiper-button-white,
  .swiper-button-next.swiper-button-white {
    --swiper-navigation-color: #ffffff;
  }
  .swiper-button-prev.swiper-button-black,
  .swiper-button-next.swiper-button-black {
    --swiper-navigation-color: #000000;
  }
  .swiper-button-lock {
    display: none;
  }
  .swiper-pagination {
    /* position: absolute; */
    text-align: center;
    transition: 300ms opacity;
    transform: translate3d(0, 0, 0);
    /* z-index: 10; */
  }
  
.all-card {
    margin: 10px;
    padding: 15px;
    box-shadow: 0px 3px 6px #0000001a;
    border-radius: 5px;
}

.customized-images {
  position: unset !important;
}

.offerViewCard {
  height: 500px;
}

.shopee-drawer__contents {
  position: absolute;
  top: 100%;
  z-index: 400;

}

.shopee-drawer {
  display: flex;
  position: relative;
  overflow: visible;
  outline: 0;
}
.WCwZOx {
  padding: 2px;
  border-radius: 2px;
  background: #fff;
  margin-top: 0.625rem;
  width: 11.25rem;
}

.WCwZOxPlace {
  padding: 2px;
  border-radius: 2px;
  background: #fff;
  margin-top: -0.375rem;
  width: 41.25rem;
  color: #000000;
}

.ZUq1cc:not(:first-child) {
  margin-left: 0.625rem;
}

.cLfmRJ {
  width: 4.375rem;
  margin-top: 0.3125rem;
}

.cLfmRJMarket {
  width: 19.375rem;
  margin-top: 0.3125rem;
}

.WFu8TV {
  width: 100%;
}

._1Hb-5J {
  padding: 0 0.9375rem 0.3125rem;
  flex-wrap: wrap;
}
._1Hb-5J {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.hkcZtW {
  height: 11.25rem;
  width: 11.25rem;
}

.quantity-picker  {
  /* display: inline-block; */
  display: initial !important;
  border: 1px solid #dfdfdf !important;
  border-radius: 3px !important;
  /* vertical-align: middle; */
  margin-left: 4px !important
}

.quantity-display {
  width: 2.1rem !important;
  padding: 0.5rem !important;
  font-size: 1rem !important;
  border: 0 !important;
  border-top: 0 solid #dbdbdb !important;
  border-bottom: 0 solid #dbdbdb !important;
  text-align: center !important;
}

.quantity-modifier {
  height: 59% !important;
  width: 2rem !important;
  font-size: 1.0rem !important;
  background: #f3f3f3 !important;
  color: #888 !important;
  border: 0 solid #dbdbdb !important;
  text-align: center !important;
  cursor: pointer !important;
}

.proImage-scroll {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}