:root{
  --primarycolor : #1976d3; 
  /* --primarycolor : #1d6042;  */
  --secondarycolor : yellowgreen; 
}
body {
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  font-family: "Roboto" !important;
  /* background-color: whitesmoke !important; */
}

.layout-color {
  background-color: #1d6042 !important;
}

.diag {
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100'><line x1='0' y1='100' x2='100' y2='0' stroke='grey' vector-effect='non-scaling-stroke'/></svg>");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%, auto;
}


.pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
  background-color: #f3f8fc;
}
.pagination > .active > a{
  border: 0;
  color: white;
}
.pagination > li{
  padding: 5px 0;
}
.pagination > li > a{
  border: 0;
  padding: 15px 15px;
  outline: none;
  cursor: pointer;
}
.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
  background-color: #cdec85;
  outline: none ;
  color: white;
}
.pagination > li > a, .pagination > li > span{
  color: #1d6042;
  border-radius: 4px;  
}
.pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
  border-radius: unset;
  padding: 0;
}
.pagination > li:first-child > a, .pagination > li:first-child > span{
  padding-left: 3px;  
}
.pagination > li:last-child > a, .pagination > li:last-child > span{
  padding-right: 3px;  
}
.pagination > li > .active{
  color: white;
  background-color: #cdec85;
}
.pagination a:hover {
  background-color: #e8f1fa;
}
.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
a :hover {
  color: #1d6042;
  font-weight: bold;
}
select{
  background-color: #cdec85;
}
option{
  background-color: #cdec85;
}
.Mui-selected{
  background-color: #cdec85;
}
.loginFormLink:hover{
  color: #1d6042;
}