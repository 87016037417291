/* @import "../node_modules/react-image-gallery/styles/css/image-gallery.css"; */

* {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
:root{
    --primarycolor : #1d6042; 
}

 /* ::-webkit-scrollbar {
    display: none;
} */
/*
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
    background-color: #F5F5F5;
    box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
}
::-webkit-scrollbar-thumb {
    background-color: #3a76d3;
} */

.containerHeader {
    /* background-color: #f5f5f5; */
    /* padding: 0 50px; */
    width: 97%;
    display: flex;
    /* justify-content: space-between !important; */
    align-items: center;
    position: fixed;
    right: 0;
    top: 100px;
    align-self: flex-start;
    background-color: white;
    z-index: 100;
    padding-left: 30px;
    padding-top:10px ;
}

.headerTabs {
    /* max-width: 1000px; */
    /* margin: auto; */
    /* margin-left: 60px; */
}
.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary > .MuiTab-wrapper {
    font-weight: 500 !important;
}

.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected > .MuiTab-wrapper {
    font-weight: bolder !important;
    color: var(--primarycolor);
}

.PrivateTabIndicator-colorPrimary-13 {
    background-color: var(--primarycolor) !important;
    margin-top: -5px !important;
    height: 4px !important;
}

/* DROPDOWN MENU on HOVER */

.containerHeader .dropdown {
    position: relative;
    display: inline-block;
}

.containerHeader .dropdown .dropbtn {
    background-color: inherit;
    border: none;
}

.containerHeader .dropdown ul {
    display: none;
    position: absolute;
    right: 0;
    background-color: white;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 999;
    list-style-type: none;
    padding-left: 0em;
}

.containerHeader .dropdown ul li {
    cursor: pointer;
    color: black;
    padding: 12px 16px;
    list-style-type: none;
    display: block;
}

.containerHeader .dropdown ul li:hover {
    background-color: var(--primarycolor);
    color: white;
}

.containerHeader .dropdown ul li:hover .subDropDownMenu {
    display: block;
}

.containerHeader .dropdown:hover ul {
    display: block;
}

.containerHeader .dropdown ul .subDropDownMenu {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 160px;
    text-align: center;
    background-color: white;
    color: var(--primarycolor);
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    width: 500px;
    height: 100%;
    overflow: hidden;
}

.subCategoriesItemContainer {
    display: flex;
    flex-wrap: wrap;
}

.subCategoriesItem {
    height: 100px;
    width: 100px;
    margin: 10px;
}

/* ------------------------------------ */

.bannerContainer {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.carousel {
    /*width: 800px;*/
    /* height: 250px; */
    margin-right: 10px;
}

.carousel img {
    height: 250px;
}

.sideBanner {
    display: flex;
    flex-direction: column;
}

.sideBanner img {
    margin-bottom: 5px;
    height: 123px;
    width: 400px;
}

.section2 {
    /* margin-top: 30px; */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.section2-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.section2-content .categoriesCard {
    margin: 20px 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.section2-content .categoriesCard img {
    height: 100px;
    width: 100px;
}

.section3 {
    /* margin-top: 30px; */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.section3-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.section3-content img {
    margin: 20px;
    height: 250px;
    width: 350px;
    background-color: yellowgreen;
}

.section4 img {
    margin-top: 25px;
    height: 300px;
    width: 100%;
    background-color: tomato;
}

.section5 {
    margin-top: 25px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.section5 .card {
    margin: 25px 10px;
    height: 280px;
    width: 300px;
    background-color: white;
    padding: 20px;
}

.cardImage {
    /* height: 150px; */
    width: 100%;
    background-color: whitesmoke;
}

.cardImage img {
    height: 180px;
}

.cardLogo img {
    margin-right: 10px;
    width: 75px !important;
    border: 0.5px solid #dcdcdc;
    border-radius: 5px;
    padding: 0.5rem;
    height: 35px;
}

.MuiFormControlLabel-root {
    margin: 0 !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
    color: var(--primarycolor) !important;
}

.cardHeading {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.cardRatings {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
}

/* STORE */

.storeContainer {
    margin: 0 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.storeCard {
    margin: 25px;
    padding-top: 10px;
    height: 200px;
    width: 200px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.storeCard:hover {
    cursor: pointer;
    box-shadow: 0 0 10px rgba(114, 110, 110, 0.6);
}

/* OfferCard */

.offerCardContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.offerCard {
    margin: 20px;
    padding: 20px;
    height: 300px;
    width: 300px;
    display: flex;
    flex-direction: column;
    background-color: white;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
}

.offerCard:hover {
    cursor: pointer;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.offerCardHeading {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.offerCardLogo img {
    margin-right: 10px;
}

.offferCardRatings {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Offers Info */

.offerInfoContainer {
    width: 100%;
    padding-top: 30px;
    display: flex;
    justify-content: center;
}

.offerInfoContainer img {
    width: 40%;
    height: 500px;
}

.offerInfoContent {
    /* padding: 50px; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 230px;
}

.offerImage {
    height: 230px;
}

.offer-image-container {
    height: 350px;
}

img.offer-thumb {
    width: 100%;
    min-height: 500px;
    max-height: 500px;
    object-fit: cover;
}

.offerInfoDetails {
}

/* SNIPS Container */

.snipsCard .form-check {
    margin-right: 10px;
    display: flex;
    align-items: center;
}

.snipsCard {
    margin: 0px 0px;
    padding: 25px;
    background-color: white;
    /* box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1); */
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.snipsCard:hover {
    /* box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2); */
}

.snipsCardDetails {
    margin: 0 25px;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    align-items: center;
    justify-content: space-between;
}

.snipsCardDetails .btn {
    justify-content: right;
}

/* categories dropdown css start here */

.dowpdown-wrapper {
    /* top: 48px; */
    /* position: absolute; */
    /* right: 0px;
    left: 0px; */
    margin: auto;
    z-index: 9999;
    padding: 4px 0;
    flex-direction: column;
}

.categories-inner {
    display: inline-flex;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    border: 1px solid var(--primarycolor);
    background: white;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
}

.dept-list-container {
    list-style: none;
    position: relative;
    display: inline-block;
    margin: 0px;
    padding: 0px;
    max-height: 75vh;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 300px;
}

.dept-list-container li {
    width: 270px;
}

.dept-list-container li a {
    font-size: 1rem;
    color: #333333;
    text-decoration: none;
    display: block;
    padding: 15px 16px;
}

.dept-category-container {
    width: 50vw;
    max-height: 75vh;
    overflow: scroll;
    list-style: none;
    /* flex-wrap: wrap;
    align-content: flex-start; */
    background-color: white;
    border-bottom-right-radius: 10px;
    border-top-color: var(--primarycolor);
    border-right-color: var(--primarycolor);
    border-bottom-color: var(--primarycolor);
    padding: 32px;
    /* display: flex; */
}

.flyout-all-dept-link:hover {
    color: #1d6042;
}
.flyout-all-dept-link {
    color: var(--primarycolor);
    text-decoration: none;
    background-color: white;
    font-size: 18px;
    padding-bottom: 16px;
    line-height: 1.5rem;
    border: none;
    font-weight: bold;
    /* flex: 1 1 100%; */
    display: block;
}

.category-list-container {
    display: flex;
    width: 100%;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 0px;
}

.small-image {
    width: 300px;
    height: 450px;
}
.category-column {
    margin-right: 80px;
    flex: 1 1 0%;
}

.category-column-section {
    margin-bottom: 8px;
}
.category-column-section a:hover {
    color: #1d6042;
    font-weight: bold;
}
.category-column-section1 {
    margin-bottom: 8px;
    list-style: none;
}

.category-special-L3-link {
    text-decoration: none;
    margin: 0px 0px 16px;
    color: var(--primarycolor);
    display: block;
    border-bottom: none;
    font-size: 0.909rem;
    line-height: 1.3125rem;
}

.category-col-header-link {
    border-bottom: 1px solid var(--primarycolor);
    text-decoration: none;
    margin-bottom: 8px;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: bold;
    color: var(--primarycolor);
    display: block;
    border-top-color: var(--primarycolor);
    border-right-color: var(--primarycolor);
    border-left-color: var(--primarycolor);
}

.category-section-items-list {
    list-style: none;
    position: relative;
    display: inline-block;
    margin: 0px;
    padding: 0px;
}

.category-section-items-list1 {
    list-style: none;
    position: relative;
    display: inline-block;
    margin: 0px 0 0 16px;
    padding: 0px;
}

.category-section-items-list a {
    display: block;
    margin: 0px 0px 8px;
    color: rgb(51, 51, 51);
    font-size: 0.909rem;
    line-height: 1.3125rem;
    text-decoration: none;
}
.category-section-items-list a:hover {
    color: #1d6042;
    font-weight: bold;
}

.dept-list-container li a.active {
    color: rgb(255, 255, 255);
    position: relative;
    background-color: var(--primarycolor);
}

.dept-list-container li a.active:after,
.dept-list-container li a.active:before {
    display: block;
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0px;
    width: 0px;
    position: absolute;
    pointer-events: none;
}

.dept-list-container li a.active:after {
    border-color: rgba(230, 230, 230, 0) rgba(230, 230, 230, 0) rgba(230, 230, 230, 0) var(--primarycolor);
    border-width: 10px;
    margin-top: -10px;
}

.department-button {
    height: 44px;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    background-color: var(--primarycolor);
    align-items: center;
    border-radius: 4px 4px 0 0;
}


.containerHeader .btn-primary.focus {
    background-color: none !important;
}

button:focus {outline: none !important;}

svg.down-arrow {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    height: 16px;
    width: 12px;
    margin: 7px 16px 9px 16px;
    pointer-events: none;
    fill: #fff;
}

svg.up-arrow {
    transform: rotate(-90deg);
    height: 16px;
    width: 12px;
    margin: 7px 16px 9px 16px;
    pointer-events: none;
    fill: rgb(255, 255, 255);
}

.ratings svg {
    margin-right: 5px;
}
.filter-ratings {
    border: lightgray 1px solid;
    padding: 5px 10px;
    display: flex;
    width: 100%;
}
.offer-page .ratings {
    margin-top: 0px;
}

.seller-overview {
    background-color: #1d6042;
    padding: 1rem;
    position: relative;
    overflow: hidden;
    border-radius: 0.25rem;
}

img.shopee-avatar__img {
    display: block;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    height: 3rem;
    width: 6rem;
    box-sizing: border-box;
    border-width: 0.25rem;
    border-color: rgba(255, 255, 255, 0.4);
    /* object-fit: contain; */
}

h1.section-seller-portrait-name {
    font-size: 1.25rem;
    line-height: 1.5rem;
    max-height: 3rem;
    font-weight: 500;
    margin-bottom: 0.3125rem;
    margin-top: 0;
    overflow: hidden;
    word-wrap: break-word;
    color: #fff;
}

.section-seller-active-time {
    vertical-align: middle;
    font-size: 0.75rem;
    color: rgba(255, 255, 255, 0.7);
    margin: 0.3125rem 0 0.375rem;
}

.section-seller-portrait-info {
    margin-top: 0.625rem;
    margin-left: 0.625rem;
    color: white;
    position: relative;
    overflow: hidden;
}

.section-seller-overview-buttons {
    position: relative;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 0.625rem;
}

.section-seller-button {
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding-right: 0.625rem;
}

.shopee-button-outline-follow {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 1.5625rem;
    color: #cdec85;
    border: 1px solid #fff;
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: 500;
    padding: 0.3125rem 0;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 2px;
    background: transparent;
    width: 100%;
    transition: background-color cubic-bezier(0.4, 0, 0.6, 1) 0.1s;
}

.shopee-button-outline {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 1.5625rem;
    color: #fff;
    border: 1px solid #fff;
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: 500;
    padding: 0.3125rem 0;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 2px;
    background: transparent;
    width: 100%;
    transition: background-color cubic-bezier(0.4, 0, 0.6, 1) 0.1s;
}

.section-seller-overview-icon svg {
    display: inline-block;
    width: 1em;
    height: 1em;
    fill: currentColor;
    position: relative;
    font-size: 0.625rem;
}

.section-seller-overview-icon {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-right: 0.625rem;
    font-size: 0.9375rem;
}

.section-info-list {
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: column wrap;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
    padding-top: 0.625rem;
    /* background-color: #fff; */
    -webkit-align-items: flex-start;
    -moz-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.section-seller-details {
    display: flex;
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
    -webkit-flex: none;
    -moz-box-flex: 0;
    -ms-flex: none;
    flex: none;
}

.section-item-icon-wrapper {
    display: inline-block;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin-left: 0.625rem;
    margin-right: 0.625rem;
    font-size: 0.9375rem;
}

.shopee-svg-icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    fill: currentColor;
    position: relative;
}

.section-item-icon-wrapper svg {
    stroke:#ffff;
    color: #ffffff;
}

.section-seller-item-text {
    display: inline-block;
    text-transform: capitalize;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.section-seller-item-text-name {
    display: inline-block;
    color: #fff
}

.section-seller-item-text-value {
    color: #fff;
}

.bonus {
    color: rgb(246, 145, 19);
    box-sizing: border-box;
    display: inline-block;
    height: 1rem;
    min-width: 0;
    position: relative;
    overflow: hidden;
    max-width: 89px;
}

.bonus:before,
.bonus:after {
    content: "";
    display: block;
    height: 0.0625rem;
    -webkit-transform: scaleY(0.5);
    transform: scaleY(0.5);
    background: currentColor;
    position: absolute;
    left: 0.25rem;
    right: 0.25rem;
}

._2DRZW:nth-of-type(1) {
    left: 0;
}

._2DRZW {
    width: 0.25rem;
    height: 1rem;
    position: absolute;
    top: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

._1FKkT {
    line-height: 1rem;
    height: 1rem;
    margin: 0 0.1875rem;
    padding: 0 calc(var(--ns-a, 0.25rem) - 0.1875rem);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    z-index: 1;
    color: white;
    background-color: rgb(246, 145, 19);
    font-size: 0.7rem;
}

._2DRZW:nth-of-type(2) {
    right: 0;
}

._2DRZW {
    width: 0.25rem;
    height: 1rem;
    position: absolute;
    top: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.discount {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    /* height: 1rem; */
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    overflow: hidden;
    /* margin-top: 0.3125rem; */
}

.store-name {
    color: rgb(255, 128, 64);
    border: 1px solid currentColor;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: default;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 0.125rem 0.25rem;
    margin-top: 0;
    margin-bottom: 0;
    height: 1rem;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    line-height: 0.75rem;
    border: 0.0625rem solid currentColor;
    border-radius: 0.0625rem;
    background-color: transparent;
    font-size: 0.625rem;
    margin-left: 0.25rem;
}

.price {
    webkit-flex-grow: 1;
    -moz-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    color: #ee4d2d;
    max-width: 100%;
    transition: all 0.1s cubic-bezier(0.4, 0, 0.6, 1);
    display: flex;
    margin-top: 0.3125rem;
    font-weight: 600;
}

.price span {
    line-height: 23px;
    letter-spacing: 0.22px;
    color: var(--primarycolor);
    margin-right: 4px;
}

.price div {
    font-size: 1rem;
    color: var(--primarycolor);
}

.unitsold {
    color: rgba(0, 0, 0, 0.87);
    margin-left: 0.3125rem;
    font-size: 0.75rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

/* .p-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    letter-spacing: 0.25px;
    color: #333333;
    text-transform: capitalize;
    font-weight: 600;
} */

.p-name {
    letter-spacing: 0.25px;
    color: #333333;
    text-transform: capitalize;
    height: 3rem;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    font-weight: 600;
    margin: 0;
    vertical-align: sub;
    line-height: 1.5rem;
    overflow: hidden;
    max-width: 100%;
    word-wrap: break-word;
}

.tag {
    height: 1rem;
    font-size: 0.75rem;
    line-height: 0.875rem;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    background-color: rgb(208, 1, 27);
    position: absolute;
    color: #fff;
    left: -0.1875rem;
    top: 0.625rem;
}

a {
    text-decoration: none !important;
}

.store-card .card-img-top {
    height: 150px;
    /* height: 200px; */
    /* object-fit: cover; */
    /* border: 1px solid #dcdcdc; */
    border-radius: 5px;
}
.store-card .card-body img {
    object-fit: contain;
}

.offer-card .card-img-top {
    /* height: 195px; */
    height: 200px;
    /* width:322px; */
    /* object-fit: cover; */
}
.offer-card .card-body img {
    object-fit: contain;
}

.banner {
    width: 100%;
    height: 175px;
    cursor: pointer;
}

/* .related-products .card-img-top {
    height: 180px;
    object-fit: contain;
} */
.related-products .card-img-top {
    /* height: 192px; */
    height: 285px;
    /* object-fit: cover; */
}
.related-products.card {
    /* border: 0 !important; */
    box-shadow: 0px 3px 6px #0000001a;
    border-radius: 5px;
}
.related-products {
    box-shadow: 0px 3px 6px #0000001a;
    border-radius: 5px;
}

.offer-page .h6 {
    font-size: 0.75rem;
    line-height: 12px;
    font-weight: 600;
}
.sslider-wrapper {
    /* width: 80%;
  margin: auto;
  position: relative;
  padding-top: 150px; */
    margin-bottom: 1rem;
}

.carousel .thumb {
    transition: border 0.15s ease-in;
    display: inline-block;
    margin-right: 6px;
    white-space: nowrap;
    overflow: hidden;
    border: 3px solid #fff;
    padding: 0 !important;
    min-width: 85px;
    max-height: 85px;
    object-fit: cover;
    min-height: 85px;
}

.carousel .thumb img {
    vertical-align: top;
    min-width: 85px;
    max-height: 85px;
    object-fit: cover;
    min-height: 85px;
}

.carousel .slide img {
    width: 100%;
    vertical-align: top;
    border: 0;
    max-height: 450px;
    object-fit: cover;
    min-height: 450px;
}

ul.control-dots {
    display: none;
}

p.carousel-status {
    display: none;
}

.delete-icon {
    background: #fff;
    border: 0;
    z-index: 99;
    right: 0px;
    position: absolute;
}

.carousel .control-disabled.control-arrow {
    display: block !important;
    opacity: 1 !important;
    display: none;
}

button.control-arrow.control-next {
    opacity: 1 !important;
    display: none;
}

.carousel .control-next.control-arrow:before {
    border-left: 8px solid #1d6042 !important;
    display: none;
}

.carousel .control-prev.control-arrow:before {
    border-right: 8px solid #1d6042 !important;
    display: none;
}

.carousel.carousel-slider .control-arrow {
    display: none !important;
}

.carousel .thumbs-wrapper {
    margin: 20px 0 !important;
}

ul.thumbs.animated {
    display: flex;
    justify-content: space-between;
    padding: 0;
}

.product-name {
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    font-weight: 500;
    margin: 0;
    vertical-align: sub;
    max-height: 3rem;
    line-height: 1.5rem;
    overflow: hidden;
    max-width: 41.5625rem;
    font-size: 1.25rem;
    word-wrap: break-word;
}

.rat {
    color: #ffc902;
    border-bottom-color: #ffc902;
}

.bg-price {
    padding: 15px 20px;
    background: #fafafa;
}

.bg-price .price {
    font-size: 1.875rem;
    font-weight: 500;
    color: var(--primarycolor);
    margin: 0;
}

.bg-price1 {
    padding: 15px 20px;
    /*background: #fafafa;*/
}

.bg-price1 .price1 {
    font-size: 1.5rem;
    font-weight: 500;
    color: var(--primarycolor);
    margin: 0;
}

button.buy-btn {
    margin-right: 15px;
    min-width: 11.25rem;
    padding: 0 0.75rem;
    font-size: 14px;
    max-width: 250px;
    background: var(--primarycolor);
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    font-size: 14px;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    box-shadow: 0 1px 1px 0 rgb(0 0 0 / 9%);
    border-radius: 2px;
    border: 0;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-transform: capitalize;
    outline: 0;
    height: 48px;
    cursor: pointer;
    color: #fff;
}

.info-label {
    color: #757575;
    width: 110px;
    text-transform: capitalize;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 34px;
}

button.product-variation {
    cursor: pointer;
    min-width: 5rem;
    min-height: 2.125rem;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0.25rem 0.75rem;
    margin: 0 8px 8px 0;
    color: rgba(0, 0, 0, 0.8);
    text-align: left;
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.09);
    position: relative;
    background: #fff;
    outline: 0;
    word-break: break-word;
    display: -webkit-inline-flex;
    display: -moz-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.product-variation-selected {
    color: var(--primarycolor) !important;
    border-color: var(--primarycolor) !important;
}

.section-title {
    background: rgba(0, 0, 0, 0.02);
    color: rgba(0, 0, 0, 0.87);
    font-size: 1.125rem;
    padding-right: 0.875rem;
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
    padding-left: 0.345rem;
    text-transform: capitalize;
}

.title-m {
    color: rgba(0, 0, 0, 0.87);
    font-size: 1.125rem;
    padding: 0.875rem 0;
    text-transform: capitalize;
}

.product-detail .label {
    font-size: 0.875rem;
    width: 6.25rem;
    margin-right: 2.5rem;
}

.product-detail ._3QRNmL {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.bQfo7W {
    font-size: 0.625rem;
    margin: 0 5px;
}

.product-description p {
    white-space: pre-wrap;
    color: rgba(0, 0, 0, 0.8);
    font-size: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.875rem;
}

.ratingForm {
    margin-left: 2rem;
}

.ratingForm .MuiSvgIcon-root {
    width: 2em;
    height: 2em;
}

span.rating-score {
    font-size: 1.875rem;
    color: var(--primarycolor);
    font-weight: 500;
}

span.rating-score-out-of {
    font-size: 1.125rem;
    color: var(--primarycolor);
    font-weight: 500;
}

.shopee-avatar__placeholder {
    position: relative;
    background-color: #f5f5f5;
    border-radius: 50%;
    overflow: hidden;
    border: 0;
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 1rem;
}

.shopee-avatar__placeholder .icon-headshot {
    stroke: #c6c6c6;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.gallery {
    max-width: 40%;
    display: flex;
}

.gallery-items {
    border: 1px solid;
}

.close-icon {
    left: 95%;
}
.image-gallery-thumbnails .image-gallery-thumbnails-container {
    text-align: left;
}

.image-gallery-play-button {
    display: none;
}

.image-gallery-fullscreen-button {
    display: none;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
    height: 60px;
    width: 60px;
}

@media only screen and (max-width: 767px) {
    .gallery {
        max-width: 100%;
    }
}

/*.image-gallery-content.top .image-gallery-thumbnails-wrapper:active+.image-gallery-slide-wrapper {
    display: block;
}

.image-gallery-content.top .image-gallery-slide-wrapper {
    display: block;
}*/

.image-gallery-slide-wrapper {
    display: none;
}

.image-gallery-slide-show {
    display: block;
}

.image-gallery-thumbnail .image-gallery-thumbnail-image {
    height: 4rem;
    width: 4rem;
    object-fit: cover;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
    /* max-height: calc(100vh - 80px); */
    max-height: 350px;
}

.snipsCard img {
    height: 6rem;
    width: 6rem;
    object-fit: contain;
}

.snipsCardDetails strong {
    width: 30%;
}

.snipsCardDetails .product-name {
    font-size: 16px;
    width: 33%;
}

.snipsCardDetails .bg-price1 {
    width: 20%;
    text-align: center;
}

.snipsCardDetails .expired {
    width: 20%;
    text-align: center;
    color: #cdec85;
    padding: 15px 20px;
}
.react-slider__picture {
    max-height: 500px;
}

.react-slider__areaZoom img {
    max-height: 500px;
    object-fit: contain;
    min-height: 500px;
}

.react-slider__ul li {
    position: relative;
    width: 100px !important;
    cursor: pointer;
    padding: 0;
    list-style: none;
    margin: 1%;
    flex: 0 0 20% !important;
    height: 100px;
}

.react-slider img {
    object-fit: contain;
}

.login-facebook {
    background: #4c69ba;
    color: #fff;
    border-radius: 0;
}

.login-facebook:hover {
    color: #fff;
}

.login-facebook svg {
    fill: #fff;
    margin-right: calc(0.34435vw + 3.38843px);
}

.login-google {
    background: #fff;
    border-radius: 0;
    border: 1px solid #00000052;
}

.login-google svg {
    margin-right: calc(0.34435vw + 3.38843px);
}

.pcmall-shopmicrofe_1ftFmB {
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    padding: 0.7rem 0.625rem 0.7rem 0.75rem;
    position: relative;
    line-height: 1rem;
    word-wrap: break-word;
    text-decoration: none;
    color: rgba(0, 0, 0, 0.8);
    display: block;
    font-weight: 500;
    width: 140px;
    -webkit-align-items: flex-start;
    -moz-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}
.pcmall-shopmicrofe_OnTpJ- {
    height: 7px;
    width: 7px;
    opacity: 0;
    position: absolute;
    left: 0;
    margin-top: 4px;
}

.pcmall-shopmicrofe_1ftFmB.active {
    color: var(--primarycolor);
    font-weight: bold;
}

.pcmall-shopmicrofe_1ftFmB.active svg {
    opacity: 1;
    fill: var(--primarycolor);
}

.shopee-sort-bar {
    font-weight: 400;
    background: rgba(0, 0, 0, 0.03);
    padding: 0.8125rem 1.25rem;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.shopee-sort-bar__label {
    color: #555;
    margin: 0 0.3125rem 0 0;
}

.sort-options {
    display: flex;
    min-width: 300px;
    justify-content: end;
    align-items: center;
}

.shopee-mini-page-controller {
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

span.shopee-mini-page-controller__current {
    color: var(--primarycolor) !important;
    stroke: var(--primarycolor) !important;
}

.shopee-sort-bar select.form-control {
    color: rgba(0, 0, 0, 0.87);
    padding: 6px 16px;
    font-size: 0.875rem;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 4px;
    letter-spacing: 0.02857em;
    height: 36px;
}

.shopee-sort-bar .MuiButton-root {
    text-transform: capitalize;
}

.shop-page-menu {
    background: white;
    box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
    margin-bottom: 1px;
}

.navbar-with-more-menu__wrapper {
    padding-top: 1.25rem;
    background-color: rgb(255, 255, 255);
}

.shop-page-menu .navbar-with-more-menu__items {
    width: 100%;
    -webkit-flex: initial;
    -moz-box-flex: initial;
    -ms-flex: initial;
    flex: initial;
    -webkit-justify-content: flex-start;
    -moz-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.shop-page-menu .navbar-with-more-menu__items {
    width: 100%;
    -webkit-flex: initial;
    -moz-box-flex: initial;
    -ms-flex: initial;
    flex: initial;
    display: flex;
    justify-content: space-around;
}

.shop-page-menu .navbar-with-more-menu__item {
    max-width: 16.67%;
}

.shop-page-menu .navbar-with-more-menu__item span {
    white-space: nowrap;
    display: inline-block;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 14px 10px;
    -webkit-flex-grow: 1;
    -moz-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    text-align: center;
}

.navbar-with-more-menu__item {
    text-decoration: none;
    color: rgba(0, 0, 0, 0.8);
    display: block;
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-bottom: 4px solid #fff;
    font-size: 0.9375rem;
    text-overflow: ellipsis;
    overflow: hidden;
}

a.navbar-with-more-menu__item.item--active {
    border-color: var(--primarycolor);
    color: var(--primarycolor);
}

.store-pro {
    text-align: center;
    display: flex;
    justify-content: center;
    color: #fff;
}

.seller-overview.store-pro img {
    margin: auto;
}

.storename {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #fff;
    z-index: 1;
    text-align: center;
    margin-top: 0.3125rem;
    width: 100%;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0 0.625rem;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    margin: 0;
}

.section-seller-overview__active-time {
    vertical-align: middle;
    font-size: 0.75rem;
    color: hsla(0, 0%, 100%, 0.7);
    margin: 0.3125rem 0 0.375rem;
}

.shop-search-page__breadcrumb-link {
    text-decoration: none;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    align-items: center;
    text-transform: uppercase;
    margin-bottom: 1.25rem;
    cursor: pointer;
}

.icon-arrow-left {
    font-size: 0.75rem;
    margin-right: 0.3125rem;
    height: 12px;
    width: 12px;
    cursor: pointer;
}

.shop-search-page__breadcrumb-link:hover {
    color: rgba(0, 0, 0, 0.8);
}

.react-slider__imgZoom {
    width: 800px !important;
}

.product-desc-table tr td:first-child {
    /* width: 180px; */
}

.MuiButton-containedPrimary {
    color: #fff;
    background-color: #1d6042 !important;
    height: 40px;
}

.MuiButton-outlinedPrimary {
    color: var(--primarycolor);
    border: 1 px solid rgb(25 118 211 / 47%);
}
.shopee-header-section__header__title {
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.54);
    font-weight: 600;
    text-transform: uppercase;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 1.25rem;
}
.shopee-header-section__header a {
    text-decoration: none !important;
    color: var(--primarycolor);
}
.shopee-header-section__header .shopee-button-no-outline,
.shopee-header-section__header .shopee-button-outline--rounded {
    text-transform: capitalize;
    display: flex;
    align-items: center;
}
.shopee-header-section__header .shopee-button-no-outline .icon-arrow-right,
.shopee-header-section__header .shopee-button-outline--rounded .icon-arrow-right {
    font-size: 0.625rem;
}

._2G_fvP .shopee-header-section__header-link > .shopee-button-no-outline {
    color: var(--primarycolor) !important;
    color: var(--brand-primary-color, var(--primarycolor)) !important;
}
.shopee-header-section__header .shopee-button-no-outline,
.shopee-header-section__header .shopee-button-outline--rounded {
    text-transform: capitalize;
    display: flex;
    align-items: center;
}
.shopee-header-section__header .shopee-button-no-outline {
    color: var(--primarycolor);
}
.shopee-header-section__header .shopee-button-no-outline,
.shopee-header-section__header .shopee-button-outline--rounded {
    text-transform: capitalize;
    display: flex;
    align-items: center;
}
.shopee-header-section__header .shopee-button-no-outline {
    color: var(--primarycolor);
}
.shopee-button-no-outline {
    padding: 0.3125rem 0.4375rem;
    color: rgba(0, 0, 0, 0.8);
    background-color: transparent;
    border: 0;
    color: #1d6042;
}
.shopee-button-no-outline,
.shopee-icon-button,
.shopee-image-button {
    outline: none;
    cursor: pointer;
    border: 0;
    font-size: 0.875rem;
    font-weight: 300;
    line-height: 1;
    letter-spacing: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.1s cubic-bezier(0.4, 0, 0.6, 1);
    color: #1d6042;
}

.more-dropdown {
    position: absolute;
    width: 560px;
    right: 16px;
    top: 53px;
    background: #fff;
    padding: 1rem;
    z-index: 999;
}

.more-dropdown a {
    display: block;
    margin: 0px 0px 8px;
    color: rgb(51, 51, 51);
    font-size: 0.909rem;
    line-height: 1.3125rem;
    text-decoration: none;
}
.more-dropdown .MuiListItem-root {
    padding-top: 0;
    padding-bottom: 0;
}

.more-dropdown .MuiListItem-button:hover {
    text-decoration: none;
    background-color: transparent;
}
.more {
    cursor: pointer;
    color: rgba(0, 0, 0, 0.54);
    text-transform: uppercase;
    font-size: 14px;
    padding: 0 12px;
}

.collection-more-dropdown {
    position: absolute;
    width: 560px;
    right: 16px;
    top: 65px;
    background: #fff;
    padding: 1rem;
    z-index: 999;
}


.collection-more-dropdown a {
    display: block;
    margin: 0px 0px 8px;
    color: rgb(51, 51, 51);
    font-size: 0.909rem;
    line-height: 1.3125rem;
    text-decoration: none;
}

.collection-more {
    cursor: pointer;
    color: rgba(0, 0, 0, 0.54);
    
    font-size: 14px;
    padding: 0 12px;
}

.more:hover {
    /* color: var(--primarycolor); */
}

.more-dropdown span:hover {
    /* color: var(--primarycolor); */
    cursor: pointer;
}
.more-dropdown:after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    top: -10px;
    right: 44px;
    border-bottom: 10px solid #fff;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
}

.menu-tab {
    margin-left: 11%;
}

.menu-tab .MuiTab-root,
.more,
.more-dropdown span {
    font-weight: 700;
    color: #212532;
}
.more-dropdown span {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 8px;
    text-transform: uppercase;
}

.dropdown-menu div div {
    padding-left: 10px !important;
}
.owl-dots {
    display: none;
}
.owl-dots {
    display: none;
}

.owl-prev {
    position: absolute;
    left: 0px;
    top: 100px !important;
    box-shadow: 7px 0px 6px -2px rgb(0 0 0 / 10%);
}

.owl-next {
    position: absolute;
    right: 0px;
    top: 100px !important;
    box-shadow: 7px 0px 6px -2px rgb(0 0 0 / 10%);
}
.owl-nav [class*="owl-"] {
    color: #333 !important;
    font-size: 54px !important;
    margin: 0px !important;
    padding: 4px 7px;
    background: #fff !important;
    display: inline-block;
    cursor: pointer;
    border-radius: none !important;
    width: 40px;
    height: 60px;
    line-height: 50px;
}

.owl-nav span {
    line-height: 10px;
    height: 20px !important;
    display: block;
}
.owl-carousel .owl-item .card-body img {
    width: unset;
}

.owl-carousel .owl-stage-outer {
    height: 360px;
    /* height: 343px; */
}
.storeinfo-wrapper{
    margin-top: 50px;
}
.storeinfo-wrapper .shopee-button-no-outline svg {
    fill: var(--primarycolor);
}

.storeinfo-wrapper .shopee-button-no-outline {
    padding: 0.3125rem 0.4375rem;
    color: rgb(25 118 211);
    background-color: transparent;
    border: 0;
}

.store-card .card-body img {
    width: 80px !important;
    border: 0.5px solid #dcdcdc;
    border-radius: 5px;
    padding: 0.5rem;
    height: 40px;
}
.offer-card .card-body img {
    width: 80px !important;
    border: 0.5px solid #dcdcdc;
    border-radius: 5px;
    padding: 0.5rem;
    height: 40px;
}
.two-row-slider .slick-prev,
.two-row-slider .slick-next {
    display: block;
    width: 40px;
    height: 80px;
    cursor: pointer;
    position: absolute;
    top: calc(50% - 50px);
    z-index: 6;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 2px 7px rgb(0 0 0 / 15%);
    font-size: 26px;
}
.two-row-slider .slick-prev {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    left: 0;
}
.two-row-slider .slick-prev,
.two-row-slider .slick-next {
    top: calc(50% - 15px);
}
.two-row-slider .slick-prev {
    left: 0;
}
.two-row-slider .slick-prev:before,
.two-row-slider .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    opacity: 1;
    color: #000;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.two-row-slider .slick-next {
    right: 15px;
}
.two-row-slider .slick-prev:hover,
.two-row-slider .slick-next:hover {
    background: #fff;
}
.two-row-slider .slick-prev:before {
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' id='Layer_1' x='0px' y='0px' viewBox='0 0 330 330' style='enable-background:new 0 0 330 330;' xml:space='preserve'%3E%3Cpath id='XMLID_222_' d='M250.606,154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213,0.001 c-5.857,5.858-5.857,15.355,0.001,21.213l139.393,139.39L79.393,304.394c-5.857,5.858-5.857,15.355,0.001,21.213 C82.322,328.536,86.161,330,90,330s7.678-1.464,10.607-4.394l149.999-150.004c2.814-2.813,4.394-6.628,4.394-10.606 C255,161.018,253.42,157.202,250.606,154.389z'/%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3C/svg%3E");
    height: 20px;
    width: 20px;
    background-size: contain;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 39%;
    transform: rotate(180deg);
}

.two-row-slider .slick-next:before {
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' id='Layer_1' x='0px' y='0px' viewBox='0 0 330 330' style='enable-background:new 0 0 330 330;' xml:space='preserve'%3E%3Cpath id='XMLID_222_' d='M250.606,154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213,0.001 c-5.857,5.858-5.857,15.355,0.001,21.213l139.393,139.39L79.393,304.394c-5.857,5.858-5.857,15.355,0.001,21.213 C82.322,328.536,86.161,330,90,330s7.678-1.464,10.607-4.394l149.999-150.004c2.814-2.813,4.394-6.628,4.394-10.606 C255,161.018,253.42,157.202,250.606,154.389z'/%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3C/svg%3E");
    height: 20px;
    width: 20px;
    background-size: contain;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 39%;
}

.two-row-slider .slick-prev:hover,
.two-row-slider .slick-prev:focus,
.two-row-slider .slick-next:hover,
.two-row-slider .slick-next:focus {
    color: transparent;
    outline: none;
    background: #ffffff;
}

/* .two-row-slider.offer .store-card .card-img-top {
    height: 192px;
    object-fit: contain;
} */

.offer-name {
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    letter-spacing: 0.25px;
    color: #333333;
    text-transform: capitalize;
    font-weight: 600;
    margin: 0;
    margin-left: 16px;
    flex: 1;
}

@media (min-width: 576px) {
    .offset-sm-mr-1 {
        margin-right: 5.333333%;
    }
}

.org-price {
    display: flex;
    margin-left: 14px;
    position: relative;
}
.org-price span {
    color: #979797;
}

.org-price:after {
    content: "";
    background: #979797;
    height: 1px;
    width: 107%;
    left: 0;
    right: 0;
    position: absolute;
    top: 12px;
    margin: auto;
}

.org-price div {
    color: #9a9a9a;
    font-size: 0.9em;
    line-height: 24px;
    font-weight: 500;
}
.activatedLink {
    color: #cdec85  !important;
}

.ReactFlagsSelect-module_selectBtn__19wW7 {
    cursor: pointer !important;
    width: 100% !important;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    padding: 1px 10px 0 10px !important;
    font-family: inherit !important;
    color: #ffffff !important;
    border: thin solid transparent !important;
    border-radius: 4px !important;
    background: transparent !important;
}
.ReactFlagsSelect-module_selectBtn__19wW7:after {
    border-top: 5px solid #ffffff !important;
    border-left: 5px solid transparent !important;
    border-right: 5px solid transparent !important;
    border-bottom: 0 !important;
    margin-top: 2px !important;
}
.ReactFlagsSelect-module_selectBtn__19wW7[aria-expanded="true"]:after {
    border-top: 0 !important;
    border-left: 5px solid transparent !important;
    border-right: 5px solid transparent !important;
    border-bottom: 5px solid #ffffff !important;
    margin-top: 2px !important;
}
.ReactFlagsSelect-module_selectOptionValue__vS99- {
    display: flex !important;
    align-items: center !important;
    color: #000 !important;
}
.ReactFlagsSelect-module_label__27pw9 {
    font-size: 12px !important;
    padding-left: 10px !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}
.brand-name {
    letter-spacing: .66px;
    color: #8b8686;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 6px;
    height: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;
    white-space: nowrap;
}
.rating {
    letter-spacing: 0.48px;
    color: #333333;
    text-transform: capitalize;
    font-weight: 600;
    font-size: 14px;
    margin-left: 6px;
    margin-bottom: 0;
}
.rate-product-label {
    border: 5px solid #1d6042;
    padding: 5px;
}

.store-card .card {
    border: 0 !important;
}
.offer-card {
    box-shadow: 5px 5px 5px #50505033;
    /* border: 0; */
}

.offer-card .card {
    border: 0 !important;
}

.offer-card-main .slick-slide {
    min-height: 343px;
}

.rating-star svg {
    margin-right: 5px;
}

.shopee-search-result-header {
    display: flex;
    flex-direction: row;
    font-size: 1rem;
    font-weight: 400;
    margin: 0;
    color: #555;
}
.shopee-search-result-header .icon-hint-bulb {
    position: relative;
    top: 0.125rem;
    width: 1rem;
    height: 1.375rem;
    margin: 0 0.75rem 0 0;
    color: #555;
}
.shopee-search-result-header__placeholder, .shopee-search-result-header__text {
    padding: 0.1875rem 0;
}
.shopee-search-result-header__text-highlight {
    color: var(--primarycolor);
    overflow: hidden;
    text-overflow: ellipsis;
}
.shopee-search-filter-status {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-transform: uppercase;
    color: rgba(0,0,0,.8);
    height: 1.875rem;
    font-weight: 700;
    font-size: 1rem;
}
.shopee-search-filter-status svg {
    font-size: .75rem;
    margin-right: 0.625rem;
    stroke: currentColor;
}
.shopee-filter-group__header {
    text-transform: capitalize;
    margin-bottom: .625rem;
    font-weight: 600;
    font-size: 14px;
}
.shopee-filter-group {
    padding: 1.25rem 0;
    border-bottom: 1px solid rgba(0,0,0,.09);
    
}
.shopee-filter-group .form-check-label {
    overflow: hidden;
    display: -webkit-box;
   /* text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    max-height: 3rem;*/
    word-wrap: break-word;
    font-size: 14px;
}
.shopee-filter-group .form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}
.shopee-filter-group__toggle-btn {
    display: flex;
    align-items: center;
    padding: .5rem .625rem .5rem 1.25rem;
    font-weight: 600;
    cursor: pointer;
    font-size:14px;
}
.shopee-filter-group__toggle-btn svg {
    font-size: .4375rem;
    margin-left: 0.625rem;
}
.slick-slide .related-products {
    margin-right: 1rem;
}

.slick-slide:last-child .related-products {
    margin: 0;
}

.text-purchased {
    color: #c45500!important;
    font-size: 12px!important;
}

.official-store-list-alphabet-letter:hover {
    text-decoration: underline;
    cursor: pointer;
}

.all-stores-container {
    width: 1200px;
}

.alphabet-bar{
    width: 1200px;
    max-width: 100%;
    min-height: 100px;
    padding: 30px 0px 15px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    transition: all .2s ease;
    border-top: 2px solid #555;
    border-bottom: 1px solid #555;
    position: sticky;
    top: 220px;
  }

.back-button {
    position: sticky;
    top: 105px;
    background-color: white;
    width: 1200px;
    height: 120px;
}

.my-list-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    padding: 30px 20px;
}

.store-header {
    padding: 20px 5px;
}

.all-checkboxes {
    position: sticky;
    bottom: 0px;
    height: 55px;
}
.scrolled{
    position: sticky;
    bottom: 0px;
}

.socail-icons > button {
    padding: 3px !important;
}

.official-store-list-section-wrapper {
    padding: 40px 0 0;
    border-bottom: 1px solid #efefef;
}

.official-store-list-section-item {
    display: flex;
}

.official-store-list-section-name {
    width: 100px;
    font-size: 44px;
    color: #333;
}

.official-store-list-section-content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.full-store-list-item {
    /* width: 20%; */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    padding: 0 25px 25px;
    box-sizing: border-box;
}

.full-store-list-item-store-cover-image {
    /* background-position: 50%; */
    /* background-repeat: no-repeat; */
    transition: all .2s cubic-bezier(.4,0,.2,1);
    width: 100%;
    /* position: relative; */
}

.full-store-list-item-cover-image {
    cursor: pointer;
    background-size: cover;
    background-size: contain;
    border: 1px solid #ebebeb;
    box-sizing: border-box;
}

.full-store-list-item-store-name {
    margin-top: 10px;
    max-width: 63%;
    font-size: 16px;
    color: #333;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    object-fit: contain;
}

.full-store-list-item-store-branch-name {
    width:10rem;
    font-size: 16px;
    color: #333;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
}

.lazy-image {
    width: 100px;
}

.lazy-image-container {
    width: 150px;
    height: 100px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.tab-text > div > span:hover {
    color: #1d6042;
}

.know-more-link {
    color: #1d6042;
    padding: 10px;
    font-weight: 500;
    cursor: pointer;
}

.shop-header-section--simple {
    padding-top: 1.25rem;
}

.shopee-search-user-item--full {
    border-bottom: 1px solid #dee2e6!important;
    background-color: #fff !important;
    margin-bottom: 20px;
}

.shop-search-user-item {
    position: relative;
    background-color: #dee2e6;
    /* height: 7.5rem; */
    padding: 8px;
    /* box-shadow: 0 1px 1px 0 rgb(0 0 0 / 5%); */
    border-radius: 0.125rem;
    overflow: hidden;
    /* display: flex; */
    align-items: center;
    text-decoration: none;
    color: rgba(0,0,0,.87);
    width: 100%;
}
.shop-avatar {
    display: inline-block;
    width: 1.875rem;
    height: 1.875rem;
    position: relative;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.shop-search-user-item__leading {
    text-decoration: none;
    color: rgba(0,0,0,.87);
    display: block;
    position: relative;
}
.shop-search-user-item__leading .shop-avatar {
    width: 4.375rem;
    height: 4.375rem;
}
.shop-avatar__placeholder {
    width: 100%;
    position: relative;
    padding-top: 100%;
    background-color: #fff;
    border-radius: 50%;
    overflow: hidden;
}
._25_r8I {
    position: relative;
}
.shop-avatar__img, .shop-avatar__img-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
}
.shop-avatar__placeholder .icon-headshot {
    stroke: #c6c6c6;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.shop-svg-icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    fill: currentColor;
    position: relative;
}
.shop-search-user-item__leading__prefer-badge-wrapper {
    position: absolute;
    bottom: 0;
    left: 50%;
    -webkit-transform: translate(-50%);
    transform: translate(-50%);
}
.shop-search-user-item__leading__prefer-badge-wrapper>.official-shop-new-badge {
    line-height: 0;
}
.qutJvu {
    height: 16px;
    width: 64px;
}
.shop-search-user-item__shop-info {
    flex: 0 1 35.9375rem;
    overflow: hidden;
    padding: 0 1.25rem 0 0.625rem;
    cursor: pointer;
    text-decoration: none;
    color: rgba(0,0,0,.87);
}
.shop-search-user-item__nickname, .shop-search-user-item__username {
    color: rgba(0,0,0,.8);
    line-height: 1.5;
    overflow: hidden;
    /* text-overflow: ellipsis; */
    white-space: nowrap;
}
.shop-search-user-item__username {
    font-size: .875rem;
}
.shop-search-user-item__follow-count {
    color: rgba(0,0,0,.54);
    margin-top: 0.3125rem;
    text-transform: capitalize;
    font-size: .75rem;
    white-space: nowrap;
}
.shop-search-user-item__follow-count-number {
    color: #1d6042;
}

.shop-search-user-item__statistics {
    display: flex;
}
.shop-search-user-seller-info-item {
    min-width: 90px;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    flex-shrink: 0;
    border-left: 1px solid rgba(0,0,0,.09);
    text-align: center;
    padding: 0 0.625rem;
}
.shop-search-user-seller-info-item__wrapper {
    display: inline-block;
    text-align: left;
}
.shop-search-user-seller-info-item__header {
    font-size: .875rem;
}
.shop-search-user-seller-info-item__header>.shop-svg-icon {
    color: #1d6042;
    margin-right: 0.5rem;
    stroke: currentColor;
}
.shop-search-user-seller-info-item__primary-text {
    color: #1d6042;
    font-size: 1rem;
    font-weight: 500;
}
.shop-search-user-seller-info-item__complement-text {
    margin-top: 0.5rem;
    color: rgba(0,0,0,.54);
    font-size: .75rem;
    text-transform: capitalize;
}
.shop-search-user-item__buttons {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 7.5rem;
    margin-left: auto;
}
.shop-search-user-item__buttons a {
    text-decoration: none;
    color: rgba(0,0,0,.87);
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 1.875rem;
    text-align: center;
    line-height: 1.875rem;
    font-size: .75rem;
    border: 1px solid rgba(0,0,0,.09);
    border-radius: 1px;
    color: rgba(0,0,0,.8);
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin-bottom: 0.625rem;
    font-weight: 400;
    text-transform: uppercase;
}
.shop-search-user-item__buttons .shop-button-outline {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: .75rem;
    color: rgba(0,0,0,.8);
    height: 1.875rem;
    border: 1px solid rgba(0,0,0,.09);
    font-weight: 400;
    text-transform: uppercase;
}
.shop-button-outline {
    outline: none;
    cursor: pointer;
    border: 0;
    font-size: .875rem;
    font-weight: 300;
    line-height: 1;
    letter-spacing: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color .1s cubic-bezier(.4,0,.6,1);
    border: 1px solid rgba(0,0,0,.09);
    border-radius: 2px;
    background: transparent;
    color: rgba(0,0,0,.8);
}

.shop-header-section__header__title {
    text-transform: none;
    color: rgba(0,0,0,.8);
    font-size: 1.25rem;
    font-weight: 500;
    margin-left: 18px;
}
.shop-search-user-item__buttons a:hover,.shop-search-user-item__buttons button:hover {
    color: #1d6042;
    border-color: #1d6042;
}

.dialog-container > div {
    background-color: rgba(0, 0, 0, 0.1) !important;
}

.dialog-container > div > div { 
    box-shadow: none !important;
    height: 85%;
}

.store-type a img {
    max-width: 80%;
    margin: 16px auto;
    display: block;
    height: 70px;
}


.product {
    padding: 36px 16px;
}
.vertical-stack {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.product-card {
    position: relative;
    width: 80%;
    max-width: 228px;
}
.product-card__merchant-logo {
    position: absolute;
    top: 16px;
    left: 16px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    border: 0.5px solid rgba(0,0,0,0.1);
    background-color: #ffffff;
    z-index: 2;
    overflow: hidden;
}
.product-card__merchant-logo__img {
    -o-object-fit: contain;
    object-fit: contain;
    width: 100%;
    height: 100%;
}
.product-card__image-wrapper {
    position: relative;
    width: 100%;
    min-height: 80px;
    border-radius: 8px;
    z-index: 1;
}
.product-card__image {
    width: 100%;
    border-radius: 8px;
}
.product-card__image-wrapper::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background: linear-gradient(0deg, rgba(0,0,0,0.1), rgba(0,0,0,0.1));
}
.product-card__title {
    font-size: 1rem;
    line-height: 1.25;
    margin-bottom:0;
    font-weight: 500;
    color: #000000;
    padding-top: 12px;
    text-align: center;
}
.product-card__description {
    font-size: 0.8rem;
    text-align: center;
    padding-top: 8px;
    font-weight:600;
}
.horizontal-stack {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top:1rem;
}
.universal-link-product-store-logos img {
    padding-right: 8px;
    height: 40px;
}

.smallImages {
    height: 130px;
    display: flex;
}

.smallImages .slick-track {
    width: 100%;
}
@media only screen and (max-width:574.94px){
    .shop-search-user-item {
        height: auto;
        padding: 0;
        display: block;
    }
    .shop-search-user-item__buttons {
        margin-left: auto;
        justify-content: center;
        margin-right: auto;
        margin-top: 1rem;
    }
}

.navigator {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    margin-top: -24px;
    padding-left: 20%;
}

.cu-breadcrumb-item {
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    text-transform: uppercase;
    font-weight: 600;
}

.sub-products {
    width: 100%;
    height: 1150px;
}

.category-scroll {
	height: 535px;
	overflow-y: scroll;
  }  

.category-scroll-view {
    height: 30px;
    border-radius: 30px;
    margin-top: -3px;
    width: 100px;
}  

.scroll-icon {
    margin-left: 33px;
    font-size: 40px!important;
    margin-top: -6px;
    color: #3a76d3;
}

.return-inner {
    width: 200px;
    height: 270px;
    text-align: left;
    overflow: auto;
    text-overflow: ellipsis
}

  
.stickyOfferHeader {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  position: absolute;
}
.store-card {
    box-shadow: 5px 5px 5px #50505033;
    width: 400px;
    /* Fixed width for all cards */
    height: 300px;
    padding: 10px;
    /* Fixed height for all cards */
}

.img-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-bottom: 10px;
    /* background-color: #f5f5f5; */
    /* Optional: To handle empty space */
}

.img-container img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    /* Ensures the image fits without distortion */
}