/* body */

.body-container {
  min-height: 440px !important;
}


/* HEADER */

.activatedLink {
  border-radius: 5px;
  background-color: rgba(161, 39, 39, 0.1) !important;
}


/* Container */

.mainContainer { 
  margin-top: 106px;
   display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
  padding: 0% 4%;
  /* overflow: hidden; */
}


/* FOOTER */

.footer {
  /* margin-top: 250px; */
  bottom: 0;
  background-color: #1d6042;
  display: flex;
  justify-content: space-between;
  padding: 25px 0px;
  /* position: fixed; */
  /* width: 100%; */
}

.footer .sections {
  display: flex;
}

.footer .sections section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/* .footer .sections .section1 {
  margin-left: 20px;
} */

.socialIcons.qrcode img {
  width: 100px;
  height: 100px;
}
.footer .sections .section2 {
  margin-left: 75px;
}
.footer .sections .section3 {
  margin-left: 75px;
}

.footer .sections section p {
  color: #F5F5F5;
  text-align: left;
  margin-bottom: 10px;
}

.shopifyBadge.qrcode img {
  width: 350px;
  height: 90px;
  margin-left: 125px;
}

.socialIcons {
  /* width: 100px; */
  display: flex;
  justify-content: space-between;
}

.fa-facebook,
.fa-instagram,
.fa-twitter,
.fa-linkedin {
/* background-color: white;
font-size: 15px !important;
margin-right: 10px; */
padding-left: 10px;
/* border-radius: 50%; */
}

.fa-store {
  padding-right: 10px;
}

.cat-search {
  /* position: absolute; */
  min-width: 22%;
  height: 42px;
  border-radius: 5px;
  /* background-color: #cdec85; */
  color: #1d6042;
  border: none;
  text-align: center;
  padding: 0px 5px;
}

.cat-search option {
  background-color: #FEFEFE;
  color: #1d6042;
}
.cat-search select {
  /* position: absolute; */
  display: block;
  top: 1px;
  left: 0;
  height: 42px;
  width: auto;
  font-family: inherit;
  visibility: visible;
  border: 0;
  border-radius: 0.25rem 0 0 0.25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  font-weight: 400;
  line-height: 1.5;
  /* background-color: #cdec85; */
  color: #1d6042;
  font-size: 14px;
  padding: 5px;
}
.cat-search select :focus,.cat-search select:focus-visible{
  outline: 0;
}
.copyRight {
  /* margin: 50px; */
  margin-right: 20px;
  color: whitesmoke;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.fa-scissors {
  padding: 5px 10px;
}


.aboutus {
  background: #1d6042;
  position: relative;
  color:#fff;
  margin-top: 2rem;
}
.aboutus img {
  transform: translateY(100px);
}

.aboutus:after {
  background: #1d6042;
  content: "";
  position: absolute;
  right: -100%;
  height: 100%;
  width: 100%;
  top: 0;
  z-index: -1;
}

.aboutus:before {
  background: #1d6042;
  content: "";
  position: absolute;
  left: -100%;
  height: 100%;
  width: 100%;
  top: 0;
  z-index: -1;
}

.aboutus h2,.aboutus-sections h2 {
  text-transform: uppercase;
}

.aboutus p {
  font-size: 42px;
  font-weight: 600;
  line-height: 52px;
}
.aboutus-sections p {
  font-size: 18px;
}
@media only screen and (max-width:576px){
  .footer .sections {
    flex-wrap: wrap;
}
.footer .sections .section2,.footer .sections .section3 {
  margin-left: 20px;
}
.footer{
  display: block;
}
.copyRight {
  margin-right: 0;
  display: block;
  text-align: center;
  margin-top: 20px;
}

/* Mobile menu Css */
.mainContainer {
  margin-top: 58px;
}
.hideinmobile{
  display: none !important;;
}
.aboutus {
  padding-top: 3rem;
  margin-top: 2rem;
}
.aboutus h2, .aboutus-sections h2 {
  font-size: 18px;
}
.aboutus img {
  transform: translateY(10px);
}
.aboutus p {
  font-size: 22px;
  line-height: 34px;
}
.aboutus-sections p{
  font-size: 16px;
}
}
